import React, { useState} from "react";

const InputPassword = ({ name, label, error,classn='',ref,hor,handleKeyUp,symbol,maxlength,desc,autofocus, ...rest }) => {

    const [showPass,setShowPass]=useState(0);

    const newName=name+"_name";

  return (

      <div className={newName+" "+(hor ? "form-group row horizontal align-item-center" : "form-group")}>
          {label ? <label className={hor ? 'col-md-3' : ''} htmlFor={name}>{label}</label> : ''}

          {
              hor ?
                  <div className='col-md-9'>
                      {symbol ?
                          <div className="input-group">
                              <div className={'password-input'}>
                                  <input autoFocus={autofocus} data-maxlength={maxlength} maxLength={maxlength} data-name={label}
                                         onKeyUp={handleKeyUp} {...rest} type={showPass?"text":"password"} name={name} id={name} ref={ref}
                                         className={'form-control ltr' + classn}/>
                                  <div onClick={()=>setShowPass(!showPass)} className={'show-pass'}>
                                      {
                                          showPass?
                                              <i className='fe-eye-off'></i>
                                              :
                                              <i className='fe-eye'></i>
                                      }

                                  </div>
                              </div>

                              <div className="input-group-prepend">
                                  <span className="input-group-text" id="inputGroupPrepend3">{symbol}</span>
                              </div>
                          </div>
                          :
                          <div className={'password-input'}>
                              <input autoFocus={autofocus} data-maxlength={maxlength} maxLength={maxlength} data-name={label}
                                     onKeyUp={handleKeyUp} {...rest} type={showPass?"text":"password"} name={name} id={name} ref={ref}
                                     className={'form-control ltr' + classn}/>
                              <div onClick={()=>setShowPass(!showPass)} className={'show-pass'}>
                                  {
                                      showPass?
                                          <i className='fe-eye-off'></i>
                                          :
                                          <i className='fe-eye'></i>
                                  }

                              </div>
                          </div>
                      }
                      {error && <small className="font-11 error-label">{error}</small>}
                      {desc && <div className="font-11 help-block">{desc}</div>}


                  </div>
                  :
                  <React.Fragment>
                      {symbol ?
                          <div className="input-group">
                              <div className={'password-input'}>
                                  <input autoFocus={autofocus} data-maxlength={maxlength} maxLength={maxlength} data-name={label}
                                         onKeyUp={handleKeyUp} {...rest} type={showPass?"text":"password"} name={name} id={name} ref={ref}
                                         className={'form-control ltr' + classn}/>
                                  <div onClick={()=>setShowPass(!showPass)} className={'show-pass'}>
                                      {
                                          showPass?
                                              <i className='fe-eye-off'></i>
                                              :
                                              <i className='fe-eye'></i>
                                      }

                                  </div>
                              </div>
                              <div className="input-group-prepend">
                                  <span className="input-group-text" id="inputGroupPrepend3">{symbol}</span>
                              </div>
                          </div>
                          :
                          <div className={'password-input'}>
                              <input autoFocus={autofocus} data-maxlength={maxlength} maxLength={maxlength} data-name={label}
                                     onKeyUp={handleKeyUp} {...rest} type={showPass?"text":"password"} name={name} id={name} ref={ref}
                                     className={'form-control ltr' + classn}/>
                              <div onClick={()=>setShowPass(!showPass)} className={'show-pass'}>
                                  {
                                      showPass?
                                          <i className='fe-eye-off'></i>
                                          :
                                          <i className='fe-eye'></i>
                                  }

                              </div>
                          </div>

                      }
                      {error && <small className="font-11 error-label">{error}</small>}
                      {desc && <div className="font-11 help-block">{desc}</div>}

                  </React.Fragment>

          }

      </div>

  );
};

export default InputPassword;
