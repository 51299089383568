import React from 'react';
import {connect} from "react-redux";
import { spilitNumber} from "../../../services/helpers";

class OrderItemProfit extends React.Component{

    render() {

        const {order}=this.props;

        return (
            <>
                <div className="row">
                    <div className="col-lg-8 col-sm-8 col-8">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3">
                                <div>
                                    شماره :
                                    <span className="mr-1">
                                        #{order.id}
                                    </span>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-5">
                                <div>
                                    <strong > {order.title}</strong>
                                </div>

                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div> میزان :
                                    <strong className={'mt-1'} style={{opacity:'1'}}>  {spilitNumber(order.total)} تومان</strong>
                                </div>
                                <div className={order.profit>=0?'text-success':'text-danger'}>سود
                                    : <strong>{spilitNumber(order.profit)}</strong>
                                </div>
                                {
                                    order.cal_profit===0?
                                        <div><small className={'badge badge-danger'}>در سود محاسبه نمی شود</small></div>
                                        :""
                                }

                            </div>


                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-4">
                        <div className="row align-items-center">

                            <div className="col-xl-6 col-lg-6">
                                <div className="">
                                    تاریخ ثبت :
                                    <span
                                        className={'badge d-inline-block mr-1 ' }>{order.order_time}</span>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="">
                                    <div>
                                        <span
                                            className={'badge d-inline-block mr-1 badge-success'}>انجام شده</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default connect ()(OrderItemProfit);
