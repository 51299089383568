import React from 'react';
import Form from "./../../../ubold/commons/form";
import Joi from "joi-browser";
import auth from "../../../modules/auth/authService";
import {Redirect,Link} from "react-router-dom";
import Recaptcha from 'react-google-invisible-recaptcha';
import {siteKey, siteUrl} from '../../../config';
import routes from "../../../routes";
import lock from "../../../assets/images/svg-icons/change-password.svg";
import $ from "jquery";

class ResetPassword extends Form {
    constructor(props) {

        super(props);

        this.state = {
            data: {
                password: "",
                password_confirmation: "",
                token_code: this.props.token,
                b:this.props.b,
                token_type:'password'
            },
            errors: {},
            loading: false,
            redirect:false
        };

        this.schema = {
            password: Joi.string()
                .required()
                .label("رمز عبور"),

            password_confirmation: Joi.string()
                .required()
                .label("تکرار رمز عبور")
        };
    }

   componentDidMount() {
       $("input").focus(function (){
           $(this).closest('.form-group').addClass('focus');
       });

       $("input").focusout(function (){
           if($(this).val()==='')
               $(this).closest('.form-group').removeClass('focus');
       });
   }


    doSubmit = async () => {
        this.recaptcha.execute().then(res => {
            this.toSubmit(res);
        }).catch(res => {
            console.log(res);
            // window.location = window.location.href;
        })
    };

    toSubmit = async (token) => {
        try {
            const {data} = this.state;
            data['g-recaptcha-response'] = token;
            this.setState({data});
            var res=await auth.resetPass(data);
            this.toggle();
            this.setState({redirect:routes.login});

            // window.location = '/';
        } catch (ex) {
            this.toggle();
            this.recaptcha.reset();
        }
    }


    onResolved = () => {
        const {data} = this.state;
        data['g-recaptcha-response'] = this.recaptcha.getResponse();
        this.setState({data});
    }

    render() {
        if (auth.getCurrentUser()) return <Redirect to="/"/>;
        if (this.state.redirect) return <Redirect to={this.state.redirect}/>;

        return (
            <div className='col-lg-5 col-md-6'>

                <div className="mb-3"  >
                    <div className="img-box">
                        <img className='d-block m-auto logo'  height={200} width={200} alt={'cafearz'} src={lock} />
                        <div className="btt"></div>
                    </div>
                    <div className="auth-title-box">
                        <h1 className="auth-title">رمز عبور جدید را وارد کنید</h1>
                    </div>
                </div>

                    <div className="card">
                        <div className="card-body auth-box ">
                            <div className="body auth-box ">
                                <form method="POST" onSubmit={this.handleSubmit}
                                      className="form-horizontal dialog-form embed">

                                    <div className="form-group rtl-label">
                                        {this.renderInput("password", "رمز عبور",'password')}
                                    </div>
                                    <div className="form-group rtl-label">
                                        {this.renderInput("password_confirmation", "تکرار رمز عبور",'password')}
                                    </div>

                                    <div className="form-group mt-4">

                                        {this.renderButton("تغییر رمز عبور", 'btn-block  btn-primary')}

                                    </div>

                                    <div className="mt-2 d-flex justify-content-between align-items-center">
                                        <Link className=" font-12 recover-pass text-t " to={routes.home}><i
                                            className='fe-arrow-right ml-1'></i>
                                            بازگشت </Link>
                                    </div>

                                    <Recaptcha
                                        ref={ref => this.recaptcha = ref}
                                        sitekey={siteKey}
                                        onResolved={this.onResolved}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

}


export default ResetPassword;

