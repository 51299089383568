import  types from './../actions/types';

export const updateUser = user => ({
  type: types.USER_SET_USER,
  user,
});

export const updateCaptcha = (img,val) => ({
  type: types.USER_SET_CAPTCHA,
  captcha_img:img,
  captcha_value:val,
});