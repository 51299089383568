import React from 'react';
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import Emitter from '../../../services/Emitter';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";
import {spilitNumber} from "../../../services/helpers";


class CostAndProfit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            to: this.props.t,
            from: this.props.f,
            costAndProfit: {},
        }

    }

    costAndProfit=()=>{
        http.post('finance/get/cost-profit-data',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    costAndProfit:response.data,
                });
            });
    }


    componentDidMount() {
        Emitter.on('reloadData', () => this.costAndProfit());
    }

    componentWillUnmount() {
        Emitter.off('reloadData');
    }

    render() {

        return (
            this.state.loadingForm ?
                <SkeleteLoading>
                    <fieldset className={'mt-4'}>
                        <legend>هزینه ها و سود خالص</legend>
                        <div className="row">
                            <div className="col-md-6"><Squre height={150}/></div>
                            <div className="col-md-6"><Squre height={150}/></div>
                            <div className="col-md-12 mt-3"><Squre height={150}/></div>

                        </div>
                    </fieldset>
                </SkeleteLoading>
                :
                <React.Fragment>
                    <fieldset className={'mt-4'}>
                        <legend>هزینه ها و سود خالص</legend>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card card-body">
                                        <div className="card-title d-flex justify-content-between align-items-center">
                                            هزینه ها
                                            <div className="balance">{spilitNumber(this.state.costAndProfit.costs)} <small>تومان</small></div>
                                        </div>
                                        <div className="items">
                                            <div className="item">
                                                <div>هزینه ها</div>
                                                <div className='value'>{spilitNumber(this.state.costAndProfit.cost)} <small>تومان</small></div>
                                            </div>
                                            <div className="item">
                                                 <div>پورسانت ها</div>
                                                <div className='value'>{spilitNumber(this.state.costAndProfit.affiliates)} <small>تومان</small></div>
                                            </div>
                                            <div className="item">
                                                 <div>پاداش ها</div>
                                                <div className='value'>{spilitNumber(this.state.costAndProfit.rewards)} <small>تومان</small></div>
                                            </div>
                                            <div className="item">
                                                <div>کارمزد درگاه زیبال</div>
                                                <div className='value'>{spilitNumber(this.state.costAndProfit.zibal_fee)} <small>تومان</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card card-body">
                                        <div className="card-title d-flex justify-content-between align-items-center">
                                             سود سایت
                                            <div className="balance">{spilitNumber(this.state.costAndProfit.profit)} <small>تومان</small></div>
                                        </div>
                                        <div className="items">
                                            <div className="item">
                                                 <div> سود سفارشات ارزی</div>
                                                <div className='value'>{spilitNumber(this.state.costAndProfit.ordersWithDollar)} <small>تومان</small></div>
                                            </div>
                                            <div className="item">
                                                 <div>سود سفارشات خدماتی</div>
                                                <div className='value'>{spilitNumber(this.state.costAndProfit.ordersWithToman)} <small>تومان</small></div>
                                            </div>
                                            <div className="item">
                                                 <div>سود کارمزدهای تسویه</div>
                                                <div className='value'>{spilitNumber(this.state.costAndProfit.zibal_checkout_fee)} <small>تومان</small></div>
                                            </div>
                                            <div className="item">
                                                <div>.</div>
                                                <div className='value'>{spilitNumber(0)} <small>تومان</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card card-body">
                                        <div className="card-title d-flex align-items-center justify-content-between">
                                            <div>سود خالص سایت :</div>
                                            <h1 className='text-success'>{spilitNumber(this.state.costAndProfit.sood)} <small>تومان</small></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </fieldset>
                </React.Fragment>

        )
    }

}

export default CostAndProfit;
