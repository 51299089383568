import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import PG from 'react-bootstrap/Pagination'


const Pagination = ({ total :lastPage, currentPage, onPageChange }) => {

  if (lastPage === 1) return null;
  const link_limit=7;
  const half_total_links = Math.floor(link_limit / 2);
  var from = currentPage - half_total_links;
  if(from < 1)
      from=1;

  var to = currentPage + half_total_links;



    if (currentPage < half_total_links) {
        to += half_total_links - currentPage;
    }
    if (lastPage - currentPage < half_total_links) {
        from -= half_total_links - (lastPage - currentPage) - 1;
    }

    if(from <= 1)
        from=1;

    if(to > lastPage)
        to=lastPage;

    const pages = _.range(from, to );

    return (
      <>
        <div className='d-flex justify-content-between align-items-center pagi'>
            <div>
                <PG>
                    <PG.Prev disabled={currentPage===1} onClick={() => onPageChange('prev')} />
                    {

                        (
                            pages.map(page =>
                                {
                                    if(from <= page && page < to)
                                        return  <PG.Item className={page===currentPage?'active':''} key={page} onClick={() => onPageChange(page)}>{page}</PG.Item>

                                }
                            )
                        )
                    }

                    <PG.Ellipsis />
                    <PG.Item className={lastPage===currentPage?'active':''} onClick={() => onPageChange(lastPage)}>{lastPage}</PG.Item>
                    <PG.Next disabled={currentPage===lastPage} onClick={() => onPageChange('next')} />
                </PG>
            </div>
            <div className="d-block d-md-none">
                <div className="current-page">
                    <small>صفحه</small>
                    &nbsp;
                    &nbsp;
                    {currentPage}
                </div>
            </div>
        </div>
      </>

  );
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
