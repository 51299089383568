import React from 'react';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";

class PageLoading extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className={"n-app-sidebar base-skelete"}>

                </div>

                <main id="panel-wrapper">

                    <div className="n-app-main">

                        <div className="app-container">

                            <div style={{position:'relative'}} className="top-header  base-skelete">

                            </div>

                            <SkeleteLoading>
                                <section className="main">

                                   <div className="mt-5 mb-4">
                                       <div className="mb-3">
                                           <div className="row">
                                               <div className="col-3"><Squre height={15} /></div>
                                           </div>
                                       </div>
                                       <div className="mb-2">
                                           <div className="row">
                                               <div className="col-4"><Squre height={15} /></div>
                                           </div>
                                       </div>
                                   </div>


                                    <Squre height={600} />
                                </section>

                            </SkeleteLoading>


                        </div>
                    </div>
                </main>

                {/*<div id="full-loading" className="page-loader-wrapper full-loading  d-flex flex-column align-self-center align-items-center  justify-content-center">*/}
                {/*    <img src={loading} height='60' />*/}

                {/*</div>*/}
            </React.Fragment>
        );
    }
}

export default PageLoading;
