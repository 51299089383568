import jwtDecode from "jwt-decode";
import http from "./../../services/httpService";
import axios from "axios";
import {apiUrl, url} from "../../config";

export const tokenKey = "token";

export async function register(formData) {
    const data= await http.submit('post', '/register', formData);
    localStorage.setItem(tokenKey, data.token);
    localStorage.setItem('user', data.user);
    return  data;
}
export async function checkUserInfo(formData) {
    return  await http.submit('post', '/register/check-user-info', formData);
}

export async function completeRegister(formData) {
    const data= await http.submit('post', '/user/complete-profile', formData);
    updateUserStorage(data.user);
    return  data;
}

export async function login(formData) {
    return await http.submit('post', "/login", formData);
}

export async function loginPassword(formData) {
    const data = await http.submit('post', "/login-password", formData);

    let res = {
        'login': false,
        'url': '/'
    };

    if (data.tfa) {
        res.url = '/tow-factor-authentication?token='+data.token+'&tfa='+data.tfa+'&m='+data.mobile;
    } else {
        res.login = true;
        localStorage.setItem(tokenKey, data.token);
        localStorage.setItem('user', data.user);
    }

    return res;
}

export async function tfaLogin(all_data,type='MOBILE') {
    var  data=false;
    if(type==='MOBILE')
         data= await http.submit('post',  '/login/2fa/validate', all_data);
    else
         data= await http.submit('post',  '/login/2fa/validate/google', all_data);

    localStorage.setItem(tokenKey, data.token);
    localStorage.setItem('user', data.user);
    return  data;
}

export async function validateRegisterMobileRegisterCode(all_data) {
    return await http.submit('post',  '/login/2fa/validate/register-mobile-active', all_data);
}

export async function forgotPass(formData) {
    return await http.submit('post',  '/forgot-password', formData);
}

export async function resetPass(formData) {
    return await http.submit('post',  '/reset-password/custom/p', formData);
}

export function logout() {
    http.post("/logout").then((data) => {
        clearStorage();
        // window.location.href=url+'c-logout';
        window.location.href = '/login';
    }).catch((error) => {
        return null;
    });
}

export async function getUser() {
    const data= await http.submit('post', "/user/get");
    localStorage.setItem('user', data.user);
    return data.user;
}

export function clearStorage() {
    localStorage.removeItem('tfa');
    localStorage.removeItem('tfa_type');
    localStorage.removeItem(tokenKey);
    localStorage.removeItem('user');
    localStorage.removeItem('tfsi');
    localStorage.removeItem('impersonating');
}

export function updateUserStorage(user) {
    localStorage.removeItem('user');
    localStorage.setItem('user', user);
}

export function getCurrentUser() {
    try {
        var user = localStorage.getItem('user');
        if (user && user !== null && user !== '') {
            return jwtDecode(user);
        }
        return null;

    } catch (ex) {
        return null;
    }
}

export async function fetchUser() {
    try {

        var user = localStorage.getItem('user');
        if (user && user !== null && user !== '') {

            const res = await http.post(  '/user/get');
            updateUserStorage(res.data.user);
            return jwtDecode(res.data.user);
        }
        return null;

    } catch (ex) {
        return null;
    }
}

export function getUserId(decode=false) {
    try {
        var uid = localStorage.getItem('tfsi');
        if (uid && uid !== null && uid !== '') {
            if(decode)
                return atob(uid);
            else
                return  uid;
        }
        return null;

    } catch (ex) {
        return null;
    }
}

export function getToken() {
    return localStorage.getItem("token");
}

export function adminLoginToUser(token) {
    axios.defaults.headers.common["Content-Type"] = 'application/json';
    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';
    axios.defaults.headers.common["Access-Control-Allow-Headers"] = '*';
    axios.defaults.headers["isApi"] = 'web';
    axios.defaults.baseURL = apiUrl;
    return  axios.post("/admin-login-to-user");
}

export default {
    login,
    register,
    completeRegister,
    tfaLogin,
    validateRegisterMobileRegisterCode,
    forgotPass,
    resetPass,
    loginPassword,
    logout,
    getCurrentUser,
    fetchUser,
    checkUserInfo,
    getUser,
    tokenKey
};
