import types from "../actions/types";
const ph={
    breadcrumbs:[
        {
            'title':'',
            'link':'/',
            'active':true,
        }
    ],
    title:'',
    buttons:'',

};
export const breadcrumbs = (state = ph, action) => {
    switch (action.type) {
        case types.BREADCRUMBS_UPDATE:
            return { ...state,
                breadcrumbs: action.breadcrumbs,
                title:action.title,
                buttons:action.buttons,
            };
        default :
            return state;
    }
}