import React from "react";

const Select = ({ name, label, options, error,defaultValue,hor, ...rest }) => {
    if (hor)
        return (
            <React.Fragment>
                   <div className={"form-group row align-item-center"}>
                       <label  className={hor?'col-md-3':''} htmlFor={name}>{label}</label>
                       <div className="col-md-9">
                           <select value={defaultValue} name={name} id={name} {...rest} className='form-control '>
                               {options.map(option => (
                                   <option  key={option._id} value={option._id}>
                                       {option.name}
                                   </option>
                               ))}
                           </select>
                           {error && <small className="font-11 error-label">{error}</small>}

                       </div>
               </div>
            </React.Fragment>
        );
    else
        return (
            <React.Fragment>
                <div className="form-group">
                    <label htmlFor={name}>{label}</label>
                    <select value={defaultValue}  name={name} id={name} {...rest} className="form-control">
                        {options.map(option => (
                            <option  key={option._id} value={option._id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    {error && <small className="font-11 error-label">{error}</small>}
                </div>
            </React.Fragment>
        );
};

export default Select;
