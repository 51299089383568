import React, { Component } from "react";
import { showAppLoader} from "../../../actions/app";
import {connect} from "react-redux";
import {adminLoginToUser, tokenKey} from "./../../auth/authService";
import PageLoading from "../../../ubold/components/layouts/PageLoading";

class swichUser extends Component {
    constructor(props) {
        super(props);
        this.props.dispatch(showAppLoader());
        this.state={
            redirect:false
        }
    }

  componentDidMount() {
      try {
          const token=this.props.match.params.token;
          const search = this.props.location.search;
          let before = new URLSearchParams(search);
          const params = Object.fromEntries(before.entries());
          before=params.before;

          if(localStorage.getItem(tokenKey) && localStorage.getItem('user')){
              window.location='/';
          }

          adminLoginToUser(token).then(res=>{
              localStorage.setItem(tokenKey, token);
              localStorage.setItem('user', res.data.user);

              if(res.data.before)
                localStorage.setItem('before', res.data.before);

              window.location='/';
          }).catch(res=>{
              console.log(res)
              // window.location='/';
          })
      }
      catch (e) {
          console.log(e)
          window.location = '/';
      }
  }

  render() {
      return <PageLoading/>
  }
}

export default connect()(swichUser);
