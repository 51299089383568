import React from "react";

const TextArea = ({ name, label, error,classn,hor,value, ...rest }) => {
  return (
      <div className={hor?"form-group row align-item-center":"form-group"}>

          <label className={hor?'col-md-3':''} htmlFor={name}>{label}</label>
          {
              hor?
                  <div className='col-md-9'>
                      <textarea {...rest} defaultValue={value}  name={name} id={name}  className={"form-control  "+ classn} />
                      {error && <small className="font-11 error-label">{error}</small>}

                  </div>
                  :
                  <React.Fragment>
                      <textarea {...rest} defaultValue={value}  name={name} id={name} className={'form-control '+classn} />
                      {error && <small className="font-11 error-label">{error}</small>}
                  </React.Fragment>

          }

      </div>
  );
};

export default TextArea;
