import React from 'react';
import {connect} from "react-redux";
import './../assets/css/owl.carousel.css';
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import SuperAdmin from "./dashboard/superadmin/SuperAdmin";
import FinanceDashboard from "./finance/FinanceDashboard";

class Dashboard extends React.Component {


    render() {
        const user = this.props.user;
        const rules = user.rules;
        const permissions = user.permissions;

        if(permissions.includes('admin_report.dashboard') || rules.includes('superadmin')){
                return <SuperAdmin/>;
        }else if(permissions.includes('admin_report.finance') || rules.includes('superadmin')){
                return <FinanceDashboard/>;
        }  else {
            return '';
        }
    }

}

const mapStateToComponent = state => {
    return {
        user: state.user.info,
        app: state.app,
    }
}

export default connect(mapStateToComponent)(Dashboard);
