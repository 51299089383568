const types= {
    USER_SET_USER : 'USER_SET_USER',
    USER_SET_MOBILE_VERIFY : 'USER_SET_MOBILE_VERIFY',
    USER_SET_CAPTCHA:'USER_SET_CAPTCHA',
    BREADCRUMBS_UPDATE:'BREADCRUMBS_UPDATE',
    APP_HIDE_LOADER:'APP_HIDE_LOADER',
    APP_SHOW_LOADER:'APP_SHOW_LOADER',
    APP_CHANGE_THEME:'APP_CHANGE_THEME',
    APP_SHOW_FAST_ACCESS:'APP_SHOW_FAST_ACCESS',
    APP_SET_PHONE_EMAIL:'APP_SET_PHONE_EMAIL',
    APP_REDIRECT:'APP_REDIRECT',
    APP_BASE_EXCHANGER:'APP_BASE_EXCHANGER',
    APP_ACTIVE_EXCHANGERS:'APP_ACTIVE_EXCHANGERS',
    APP_VERSION:'APP_VERSION',
    APP_SOW_EVENT:'APP_SOW_EVENT',
}


export default types;