import React from 'react';
import MobileTfa from "./MobileTfa";
import GoogleTfa from "./GoogleTfa";
import { Redirect} from "react-router-dom";


class Tfa extends React.Component {

    constructor(props) {
        super(props);

        const search = this.props.location.search;
        const token = new URLSearchParams(search).get('token');
        const tfa = new URLSearchParams(search).get('tfa');

        this.state = {
            token: token,
            tfa:tfa
        };
    }

    render() {
        switch (this.state.tfa) {
            case 'MOBILE':
                return <MobileTfa token={this.state.token} tfa={this.state.tfa} {...this.props}/>;
            case 'GOOGLE':
                return <GoogleTfa token={this.state.token} tfa={this.state.tfa}/>;
            default:
                return <Redirect to='/'/>
        }
    }
}

export default Tfa;
