import React from 'react';
import SidebarContent from "./parts/SidebarContent";
import {spilitNumber} from "../../../services/helpers";

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width:  window.innerWidth
        }
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth});
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }


    render() {
        const {user}=this.props;
        const balance = user.balance;

        return (
            <React.Fragment>
                <div onClick={()=>this.props.toggleSideMenu(false)} className="close d-block d-md-none">
                    <i className='fe-x'></i>
                </div>

                <img className='caferazimg' height={60} src={'/assets/images/'+(this.props.darktheme?"cafearz-dark":"cafearz")+'.svg'} />
                <div className='d-flex align-items-center user-info'>
                    <img className='avatar ml-2' alt={user.name} src={user.avatar} height={50} width={50} />
                    <div>
                        <h4 className='name'>
                            {user.name}
                        </h4>
                        <div className='email'>
                            {user.email}

                        </div>
                    </div>
                </div>

                <hr/>

                <SidebarContent/>

                {/*{this.state.width < 1000 ?*/}
                {/*    <SidebarContent/>*/}
                {/*    :*/}
                {/*    <StickyBox offsetTop={30} offsetBottom={20}>*/}
                {/*        <SidebarContent/>*/}
                {/*    </StickyBox>*/}
                {/*}*/}
            </React.Fragment>
        );
    }
}


export default Sidebar;