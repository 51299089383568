import React from 'react';
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import Emitter from '../../../services/Emitter';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";
import {spilitNumber} from "../../../services/helpers";
import {CChart} from "@coreui/react-chartjs";

class OrdersServiceChartsByType extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            to: this.props.t,
            from: this.props.f,
            charts: {},
        }

    }

    getCharts=()=>{
        http.post('orders/get/chart-type/'+this.props.type,{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    chart:response.data.chart,
                });
            });
    }


    componentDidMount() {
        Emitter.on('reloadData', () => this.getCharts());
    }

    componentWillUnmount() {
        Emitter.off('reloadData');
    }

    render() {

        return (
            this.state.loadingForm ?
                <SkeleteLoading>
                    <div className=" mt-4">
                        <Squre height={300}/>
                    </div>
                </SkeleteLoading>
                :
                <React.Fragment>

                        {
                            this.state.chart ?
                                <div className='card card-body mb-4'>
                                    <div className="card-title">تعدا سفارش ها</div>
                                    <CChart
                                        height={90}
                                        type="line"
                                        data={{
                                            labels: this.state.chart.label,
                                            datasets: [
                                                {
                                                    label: this.state.chart.title,
                                                    backgroundColor: "rgba(220, 220, 220, 0.2)",
                                                    borderColor:  "rgb(23, 165, 2)",
                                                    pointBackgroundColor: "rgb(23, 165, 2)",
                                                    data: this.state.chart.data
                                                }
                                            ],
                                        }}
                                    />
                                </div>
                                :

                                <div>
                                    <div className="card card-body text-center">
                                        داده برا نمایش وجود ندارد
                                    </div>
                                </div>
                        }

                </React.Fragment>


        )
    }

}

export default OrdersServiceChartsByType;
