import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import routes from "../../../../routes";
import $ from "jquery";
const feather = require('feather-icons');

class SidebarContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width:  window.innerWidth
        }
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth});
    }

    handleMenuOpenClose=(el)=>{
        if ($(el).hasClass('show')){
            $(el).removeClass('show');
            $('body').css('overflow-y','auto')
        }
        else
        {
            $(el).addClass('show');
            $('body').css('overflow-y','hidden')
        }
    }

    componentDidMount() {
        const me=this;
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));

        $(function () {

            $('.close-menu').click(function () {
                $('.left-sidebar').removeClass('show');
                $('body').css('overflow-y','auto')
            });

            var menu_ul_a = $(".sidebar .menu ul > li a");
            var menu_ul_li = $(".sidebar .menu ul > li");
            var menu_ul_li_li = $(".sidebar .menu ul > li  li");

            menu_ul_a.click(function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).parent().removeClass('active');

                } else {
                    menu_ul_a.removeClass('active');
                    menu_ul_li_li.removeClass('active');

                    if($(this).hasClass('has-sub')){
                        menu_ul_li.removeClass('active');
                        $(this).parent().addClass('active');
                    }

                    $(this).addClass('active');
                }

                if(me.state.width < 1000 && !$(this).hasClass('has-sub')){
                    $('.left-sidebar').removeClass('show');
                    $('body').css('overflow-y','auto')
                }

            });

        })
    }

    render() {

        const user = this.props.user;
        const rules = user.rules;

        return (
            <section className="left-sidebar">
                <div className="sidebar">
                    <div className="clearfix"></div>
                    <div className="menu active">
                        <ul className="" id="">
                            <li className="">
                                <Link className=""  to="/">
                                    <i className="fe-home i"></i>
                                    <span className="title">داشبورد</span>
                                </Link>
                            </li>
                            <li className="nav-second-level  ">
                                <a className=" has-sub" href="#profile">
                                    <i className="fe-dollar-sign i "></i>
                                    <span className="title">مالی</span>
                                    <i className='a-down' dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>
                                </a>
                                <ul className="has-submenu">
                                    <li className="   ">
                                        <Link className=" " to={routes.finance_dashboard}>
                                            <i className=""></i>
                                            <span className="title">داشبورد مالی</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-second-level  ">
                                <a className=" has-sub" href="#profile">
                                    <i className="fe-shopping-cart i "></i>
                                    <span className="title">سفارشات</span>
                                    <i className='a-down' dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>
                                </a>
                                <ul className="has-submenu">
                                    <li className="   ">
                                        <Link className=" " to={routes.orders_dashboard}>
                                            <i className=""></i>
                                            <span className="title">داشبورد سفارشات</span>
                                        </Link>
                                    </li>
                                    <li className="   ">
                                        <Link className=" " to={routes.orders_category}>
                                            <i className=""></i>
                                            <span className="title">گزارش  با  نوع سرویس </span>
                                        </Link>
                                    </li>

                                    <li className="   ">
                                        <Link className=" " to={routes.orders_sort_profit}>
                                            <i className=""></i>
                                            <span className="title">مرتب سازی با  سود سفارشات </span>
                                        </Link>
                                    </li>

                                    <li className="   ">
                                        <Link className=" " to={routes.orders_cooperation}>
                                            <i className=""></i>
                                            <span className="title">گزارش بر اساس همکار و کاربر </span>
                                        </Link>
                                    </li>
                                    <li className="   ">
                                        <Link className=" " to={routes.orders_sale_profit}>
                                            <i className=""></i>
                                            <span className="title">گزارش فروش و سود </span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-second-level  ">
                                <a className=" has-sub" href="#profile">
                                    <i className="fe-user i "></i>
                                    <span className="title">کاربران</span>
                                    <i className='a-down' dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>
                                </a>
                                <ul className="has-submenu">
                                    <li className="   ">
                                        <Link className=" " to={routes.user_dashboard}>
                                            <i className=""></i>
                                            <span className="title">داشبورد کاربران</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-second-level  ">
                                <a className=" has-sub" href="#profile">
                                    <i className="fe-dollar-sign i "></i>
                                    <span className="title">نقد ها</span>
                                    <i className='a-down' dangerouslySetInnerHTML={{__html: feather.icons['chevron-down'].toSvg()}}/>
                                </a>
                                <ul className="has-submenu">
                                    <li className="   ">
                                        <Link className=" " to={routes.cashes_dashboard}>
                                            <i className=""></i>
                                            <span className="title">داشبورد نقد ها</span>
                                        </Link>
                                    </li>
                                    <li className="   ">
                                        <Link className=" " to={routes.cashes_category}>
                                            <i className=""></i>
                                            <span className="title">گزارش  با  نوع سرویس </span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>


                            <li><hr/></li>
                            <li className="  ">
                                <Link className=" " to={'/logout'}>
                                    <i className="fe-log-out i"></i>
                                    <span  className="title">خروج از پنل ادمین</span>
                                </Link>
                            </li>

                        </ul>


                    </div>


                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.info
    }
}

export default connect (mapStateToProps)(SidebarContent) ;