import React from 'react';
import {Link} from "react-router-dom";

class NoMatch extends React.Component {

    render() {
        return (
            <div className='page-not-found d-flex flex-column align-items-center justify-content-center '>
                <img alt={'خطای 404'} height={200} width={200} src='/assets/images/error-404.svg' />
                <p>صفحه مورد نظر یافت نشد</p>
                <div>
                    <Link className='btn btn-primary btn-round' to='/'>بازگشت به صفحه اصلی</Link>
                </div>
            </div>
        );
    }

}

export default NoMatch;
