import React from 'react';

class Squre extends React.Component {

    render() {
        return (
            <div style={{height:this.props.height?(this.props.height):''}} className='base squre'></div>
        )
    }
}

export default Squre;
