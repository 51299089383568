import React from 'react';
import Form from "./../../../ubold/commons/form";
import Joi from "joi-browser";
import {Link, Redirect} from "react-router-dom";
import {checkVerifyTokenCode, confirmCode, sendVerifyCode} from "../../user/userService";
import {connect} from "react-redux";
import {getCurrentUser, updateUserStorage} from "../authService";
import loading from "../../../assets/images/loading.svg";
import AuthLayout from "./AuthLayout";
import {secondsToHms} from "../../../services/helpers";
import lock from "../../../assets/images/svg-icons/tfa.svg";
import OtpInput from "react-otp-input";

var timer = null;

class MobileEmailVerify extends Form {

    constructor(props) {
        super(props);

        const type = (this.props.type).toUpperCase();
        this.state = {
            data: {code: ""},
            type: type,
            label: (type === 'MOBILE' ? 'موبایل' : 'ایمیل'),
            errors: {},
            loading: false,
            loadingToken: false,
            c: 60,
            t: null,
            activeSendCodeButton: false
        };
        this.schema = {
            code: Joi.string()
                .required()
                .label("کد احراز هویت")
        };

    }

    componentDidMount = async () => {
        const res = await checkVerifyTokenCode(this.state.type);

        this.setState({
            c: parseInt(res.seconds),
        });

        if (parseInt(res.seconds) > 0) {
            this.startCount();
            this.setState({activeSendCodeButton: true});
        }

    }

    showSeconds = () => {
        var cm = this.state.c;
        if (cm === 0) {
            this.stopCount();
        } else {
            cm = cm - 1;
            this.setState({c: cm, t: secondsToHms(cm)});
        }
    };

    startCount = () => {
        timer = setInterval(this.showSeconds, 1000);
    }

    stopCount = () => {
        clearInterval(timer);
        this.setState({c: 0, t: null});
        this.setState({activeSendCodeButton: false});
    }

    sendToken = async () => {
        this.setState({activeSendCodeButton: true, loadingToken: true});

        const res = await sendVerifyCode(this.state.type);

        this.setState({
            c: parseInt(res.seconds),
            loadingToken: false
        });

        this.startCount();

    }

    doSubmit = async () => {
        try {
            const res = await confirmCode(this.state.type, this.state.data.code);
            updateUserStorage(res.user);
            window.location.href = '/';
        } catch (ex) {
            this.toggle();
        }
    }

    handleChangeOtp = (otp) => {
        const data = this.state.data;
        data['code'] = otp;

        this.setState({data});

        if (otp.length >= 6) {
            this.doSubmit();
        }
    }

    render() {
        var user = getCurrentUser();
        if (!user || (user && user.mobile_verify)) return <Redirect to="/"/>;

        return (
            <AuthLayout>
                <div className='col-lg-5 col-md-6'>
                    <div className="col-12 ">
                        <div className="mb-3">
                            <div className="img-box">
                                <img className='d-block m-auto logo' height={200} width={200} alt={'cafearz'}
                                     src={lock}/>
                                <div className="btt"></div>
                            </div>
                            <div className="auth-title-box">
                                <h1 className="auth-title">تایید شماره همراه</h1>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body auth-box ">
                            <div className="auth-title-box mb-4">
                                <div className='help text-center mb-2'>کد تایید برای شماره همراه <strong
                                    className='d-inline-block ml-1 mr-1'>{this.props.user.mobile}</strong> ارسال شده است
                                </div>
                            </div>
                            <form method="POST" onSubmit={this.handleSubmit}
                                  className="form-horizontal dialog-form embed">

                                <div className="form-group rtl-label">
                                    <div
                                        className={'otp-code ' + ((this.state.errors && this.state.errors['code']) && 'error')}>
                                        <OtpInput
                                            value={this.state.data.code}
                                            onChange={this.handleChangeOtp}
                                            numInputs={6}
                                            separator={''}
                                            shouldAutoFocus={true}
                                            isInputNum={true}
                                            hasErrored={true}
                                        />
                                    </div>
                                </div>

                                <div className="text-center mb-3 mt-4">
                                    <button style={{display: 'inline-block'}} disabled={this.state.activeSendCodeButton} type="button"
                                            onClick={this.sendToken} id="sendCode"
                                            className="send-code m-auto text-t text-center m-auto">
                                        {this.state.loadingToken ?
                                            <div className='d-inline-block'>
                                                <img  height='20' className='ml-1 ' src={loading} />
                                                در حال ارسال ...
                                            </div>
                                            :"ارسال مجدد کد فعال سازی"}

                                        {
                                            this.state.c > 0 ?
                                                <span className='mr-2'  id="ccc">(<span
                                                    id="counter">{this.state.t}</span>)</span>
                                                :""
                                        }
                                    </button>
                                </div>

                                <div className="form-group">
                                    {this.renderButton("تایید ", 'btn-block btn-primary')}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </AuthLayout>
        );
    }

}


const mapStateToProps = state => {
    return {
        user: state.user.info,
        app: state.app
    }
}

export default connect(mapStateToProps)(MobileEmailVerify);
