import React from 'react';
import Form from "../../../../ubold/commons/form";
import Joi from "joi-browser";
import auth from "../../authService";
import {Link, Redirect} from "react-router-dom";
import $ from "jquery";
import lock from "../../../../assets/images/svg-icons/tfa.svg";
import routes from "../../../../routes";
import OtpInput from "react-otp-input";

class GoogleTfa extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {totp: "", token: this.props.token, tfa_type: this.props.tfa.toUpperCase()},
            errors: {},
            loading: false,
            counter: 0,
            be_counter: 0,
        };
        this.schema = {
            totp: Joi.string()
                .required()
                .label("کد احراز هویت")
        };
    }

    doSubmit = async () => {
        try {
            const {data} = this.state;
            const res = await auth.tfaLogin(data, 'GOOGLE');

            if (res.user)
                window.location.href = '/';

        } catch (ex) {
            this.toggle();
        }
    };

    componentDidMount() {
        const me = this;

        $(".input-code").keyup(function () {

            me.setState({counter: $(this).val().length});

            if (me.state.be_counter >= 6) {
                me.setState({be_counter: $(this).val().length});
                return false;
            } else {
                if (me.state.counter >= 6) {
                    me.toggle();
                    me.doSubmit();
                }

                me.setState({be_counter: $(this).val().length});
            }
        });
    }

    handleChangeOtp = (otp) => {
        const data = this.state.data;
        data['totp'] = otp;


        this.setState({data});

        if (otp.length >= 6) {
            this.doSubmit();
        }

    }

    render() {
        if (auth.getCurrentUser()) return <Redirect to="/"/>;
        return (

            <React.Fragment>
                <div className='col-lg-5 col-md-6'>
                    <div className="col-12 ">
                        <div className="mb-3">
                            <div className="img-box">
                                <img className='d-block m-auto logo' height={200} width={200} alt={'cafearz'}
                                     src={lock}/>
                                <div className="btt"></div>
                            </div>
                            <div className="auth-title-box">
                                <h1 className="auth-title"> ورود دو مرحله ای گوگل</h1>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                    <div className="body auth-box ">
                        <div className="auth-title-box mb-4">
                            <div className='help text-center mb-2'>کد تایید را از برنامه Google Authenticator مشاهده کنید
                            </div>

                        </div>

                        <form method="POST" onSubmit={this.handleSubmit}
                              className="form-horizontal dialog-form embed">

                            <div className="form-group-c">
                                <div
                                    className={'otp-code ' + ((this.state.errors && this.state.errors['totp']) && 'error')}>
                                    <OtpInput
                                        value={this.state.data.totp}
                                        onChange={this.handleChangeOtp}
                                        numInputs={6}
                                        separator={''}
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        hasErrored={true}
                                    />
                                </div>
                            </div>


                            <div className="form-group">
                                {this.renderButton("  ورود به سایت", 'btn-block ')}
                            </div>

                            <div className="mt-2 d-flex justify-content-between align-items-center">
                                <Link className=" font-12 recover-pass text-t" to='/'><i
                                    className='fe-arrow-right ml-1'></i>
                                    بازگشت </Link>


                            </div>

                        </form>
                    </div>
                </div>
                </div>
            </React.Fragment>
        );
    }

}

export default GoogleTfa;
