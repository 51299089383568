import React from 'react';

import "react-toastify/dist/ReactToastify.css";
import "./assets/my-panel/panel.scss";
import {connect} from "react-redux";
import {setCookie} from "./services/helpers";
import AuthPages from "./modules/auth/components/AuthPages";
import Panel from "./modules/Panel";
import $ from "jquery";


class App extends React.Component {

    constructor(props) {
        super(props);
        const store = this.props.store;
        store.subscribe(() => {
            this.setState(store.getState(), () => this.handleTheme());
        })
    }

    componentDidMount() {

    }

    componentDidUpdate() {
        $(function (){
            $("body").removeClass('no-overflow');
        });
    }

    handleTheme = () => {
        // if (this.state.app.dark_theme === 1)
        //     document.body.classList.add('dark');
        // else
        //     document.body.classList.remove('dark');
    }

    render() {
        return (
            <>
                <AuthPages>
                    <Panel store={this.props.store}/>
                </AuthPages>
            </>
        );
    }
}


const mapStateToProps = (state) => {

    return {
        app: state.app,
    }
}

export default connect(mapStateToProps)(App);


