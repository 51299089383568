import React from 'react';
import Form from "./../../../../ubold/commons/form";
import Joi from "joi-browser";
import auth from "../../authService";
import {Link, Redirect} from "react-router-dom";
import $ from "jquery";
import loading from "../../../../assets/images/loading.svg";
import {sendTokenCode,checkTokenCode} from "../../../user/userService";
import {secondsToHms} from "../../../../services/helpers";
import routes from "../../../../routes";
import lock from "../../../../assets/images/svg-icons/tfa.svg";
import OtpInput from 'react-otp-input';

var timer=null;

class MobileTfa extends Form {

    constructor(props) {
        super(props);

        const search = window.location.search;
        const mobile = new URLSearchParams(search).get('m');

        this.state = {
            data: {code: "", token: this.props.token, tfa_type: this.props.tfa.toUpperCase()},
            errors: {},
            loading: false,
            loadingToken:false,
            counter: 0,
            t: null,
            activeSendCodeButton: false,
            mobile: mobile
        };

        this.schema = {
            code: Joi.string()
                .required()
                .label("کد احراز هویت")
        };
    }

    componentDidMount = async () => {
        const res = await checkTokenCode(this.state.data.token);

        this.setState({
            counter:parseInt(res.seconds),
        });

        if(res.seconds > 0){
            this.startCount();
            this.setState({activeSendCodeButton:true});
        }
    }

    showSeconds = () => {
        var cm = this.state.counter;
        if (cm === 0) {
            this.stopCount();
        } else {
            cm = cm - 1;
            this.setState({counter: cm,t:secondsToHms(cm)});
        }
    };

    startCount = () => {
        timer = setInterval(this.showSeconds, 1000);
    }

    stopCount = () => {
        clearInterval(timer);
        this.setState({c: 0,t:null});
        this.setState({activeSendCodeButton: false});
    }

    sendToken = async () => {
        this.setState({activeSendCodeButton: true,loadingToken:true});
        const res = await sendTokenCode(this.state.data.token);
        this.setState({counter:res.seconds,loadingToken:false});
        this.startCount();
    }

    doSubmit = async () => {
        this.setState({
            loading: true
        });

        try {
            const {data} = this.state;
            const res = await auth.tfaLogin(data);

            if (res.user)
                window.location.href = '/';

        } catch (ex) {
            this.setState({
                loading: false
            });
        }
    }

    handleChangeOtp = (otp) => {
        const data = this.state.data;
        data['code'] = otp;

        this.setState({data});

        if (otp.length >= 5) {
            this.doSubmit();
        }
    }

    render() {

        if (auth.getCurrentUser()) return <Redirect to="/"/>;
        return (
            <React.Fragment>
                <div className='col-lg-5 col-md-6'>
                    <div className="col-12 ">
                        <div className="mb-3">
                            <div className="img-box">
                                <img className='d-block m-auto logo' height={200} width={200} alt={'cafearz'}
                                     src={lock}/>
                                <div className="btt"></div>
                            </div>
                            <div className="auth-title-box">
                                <h1 className="auth-title"> ورود دو مرحله ای</h1>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body auth-box ">
                            <div className="auth-title-box mb-4">
                                <div className='help text-center mb-2'>کد تایید برای شماره همراه <strong
                                    className='d-inline-block ml-1 mr-1'>{this.state.mobile}</strong> را وارد کنید
                                </div>
                                <div className='help text-center'>آیا شماره موبایل اشتباه است؟
                                    <Link href='#' to={routes.login} className="text-t mr-2 text-primary">ویرایش
                                        کنید</Link>
                                </div>
                            </div>
                            <form method="POST" onSubmit={this.handleSubmit}
                                  className="form-horizontal dialog-form embed">

                                <div className="form-group-c">
                                    <div
                                        className={'otp-code ' + ((this.state.errors && this.state.errors['code']) && 'error')}>
                                        <OtpInput
                                            value={this.state.data.code}
                                            onChange={this.handleChangeOtp}
                                            numInputs={5}
                                            separator={''}
                                            shouldAutoFocus={true}
                                            isInputNum={true}
                                            hasErrored={true}
                                        />
                                    </div>
                                </div>

                                <div className="text-center mb-3 mt-4">
                                    <button style={{display: 'inline-block'}} disabled={this.state.activeSendCodeButton} type="button"
                                            onClick={this.sendToken} id="sendCode"
                                            className="send-code m-auto text-t text-center m-auto">
                                        {this.state.loadingToken ?
                                            <div className='d-inline-block'>
                                                <img  height='20' className='ml-1 ' src={loading} />
                                                در حال ارسال ...
                                            </div>
                                            :"ارسال مجدد کد فعال سازی"}

                                        {
                                            this.state.counter > 0 ?
                                                <span className='mr-2'  id="ccc">(<span
                                                    id="counter">{this.state.t}</span>)</span>
                                                :""
                                        }
                                    </button>
                                </div>

                                <div className="form-group">
                                    {this.renderButton("تایید ", 'btn-block ')}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        );
    }

}

export default MobileTfa;
