import  types from './../actions/types';

export const hideAppLoader = () => ({
  type: types.APP_HIDE_LOADER,
});

export const setAppRedirect = (redirect) => ({
  type: types.APP_REDIRECT,
  redirect:redirect
});

export const showAppLoader = () => ({
  type: types.APP_SHOW_LOADER,
});

export const changeTheme = (hasDark) => ({
  type: types.APP_CHANGE_THEME,
  dark_theme: hasDark
});

export const showFastAccess = (access) => ({
  type: types.APP_SHOW_FAST_ACCESS,
  fastAccess: access
});

export const setEmailPhoneApp = (email,phone) => ({
  type: types.APP_SET_PHONE_EMAIL,
  email: email,
  phone: phone,
});

export const setCryptoBaseExchanger = (baseExchanger) => ({
  type: types.APP_BASE_EXCHANGER,
  crypto_base_exchanger: baseExchanger,
});

export const setCryptoActiveExchangers = (activeExchangers) => ({
  type: types.APP_ACTIVE_EXCHANGERS,
  crypto_active_exchangers: activeExchangers,
});

export const setVersion = (version) => ({
  type: types.APP_VERSION,
  app_version: version,
});


export const setShowEvent = (type) => ({
  type: types.APP_SOW_EVENT,
  show_sidebar: type,
});