import React from 'react';
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import {CChart} from "@coreui/react-chartjs";

import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import Emitter from '../../../services/Emitter';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";

class OrderData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            to: this.props.t,
            from: this.props.f,
            orderChart:false,
        }
    }

    getOrderData=()=>{
        http.post('dashboard/get/order-data',{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    orderChart:response.data.orderChart,
                });
            });
    }


    componentDidMount() {
        Emitter.on('reloadData', () => this.getOrderData());

    }

    componentWillUnmount() {
        Emitter.off('reloadData');
    }

    render() {

        return (
            this.state.loadingForm ?
                <SkeleteLoading>
                    <div className=" mt-4 mb-4">
                        <Squre height={300}/>
                    </div>
                </SkeleteLoading>
                :
                <React.Fragment>
                    <div className="card card-body dashboard-rr chart-data mt-4">
                        <div className="card-title">سفارش</div>
                        {this.state.orderChart?
                            <CChart
                                height={90}
                                type="line"
                                data={{
                                    labels: this.state.orderChart.all.label,
                                    datasets: [
                                        {
                                            fillColor : "rgba(250,174,50,0.5)",
                                            label: this.state.orderChart.all.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor: "rgba(56,38,4,0.51)",
                                            pointBackgroundColor: "rgb(44,27,3)",
                                            pointBorderColor: "#fff",
                                            data: this.state.orderChart.all.data
                                        },
                                        {
                                            label: this.state.orderChart.done.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor: "rgba(40,182,8,0.51)",
                                            pointBackgroundColor: "rgb(52,182,8)",
                                            pointBorderColor: "#fff",
                                            data: this.state.orderChart.done.data
                                        },
                                        {
                                            label: this.state.orderChart.cancel.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor: "rgba(208,3,3,0.51)",
                                            pointBackgroundColor: "rgb(203,12,12)",
                                            pointBorderColor: "#fff",
                                            data: this.state.orderChart.cancel.data
                                        },
                                        {
                                            label: this.state.orderChart.other.title,
                                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                                            borderColor: "rgba(8,69,182,0.51)",
                                            pointBackgroundColor: "rgb(8,49,182)",
                                            pointBorderColor: "#fff",
                                            data: this.state.orderChart.other.data
                                        }
                                    ],
                                }}
                            />
                            :''}
                    </div>
                </React.Fragment>

        )
    }

}

export default OrderData;
