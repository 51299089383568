import React from "react";

const CheckBox = ({checked,...rest }) => {
  return (
      checked?
        <input  {...rest} checked type='checkbox'  />
      :
        <input  {...rest}  type='checkbox'  />

  );
};

export default CheckBox;
