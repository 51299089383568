import React from 'react';
import {connect} from 'react-redux';
import { NavLink} from "react-router-dom";

class Breadcrumbs extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-between align-items-center app-header-breadcrumbs">
                    <div>
                        <h1 className="page-title">
                            <span>{this.props.title}</span>
                        </h1>
                        {
                            this.props.breadcrumbs.length ?
                                <nav aria-label="breadcrumb" className={'d-none d-md-block'}>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><NavLink to="/">خانه</NavLink></li>
                                        {this.props.breadcrumbs.map(item => (
                                            item.active
                                                ? <li key={item.title} className="breadcrumb-item active">{item.title}</li>
                                                : <li key={item.title} className="breadcrumb-item"><NavLink
                                                    to={item.link}>{item.title}</NavLink></li>
                                        ))}
                                    </ol>

                                </nav>
                                :''
                        }

                    </div>

                    <div className="header-buttons">
                        {this.props.buttons}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        breadcrumbs: state.breadcrumbs.breadcrumbs,
        title: state.breadcrumbs.title,
        buttons: state.breadcrumbs.buttons,
    }
};


export default connect(mapStateToProps)(Breadcrumbs);
