import React from 'react';
import { Redirect} from "react-router-dom";
import routes from "../routes";

class NoMatchRedirect extends React.Component {

    render() {
        return <Redirect to={routes.login} />
    }

}

export default NoMatchRedirect;
