import React from 'react';
import http from "./../../../services/httpService";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import '@coreui/chartjs/scss/coreui-chartjs.scss';
import Emitter from '../../../services/Emitter';
import Squre from "../../../components/skelete/Squre";
import SkeleteLoading from "../../../components/SkeleteLoading";
import {spilitNumber} from "../../../services/helpers";

class CashesServiceDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            to: this.props.t,
            from: this.props.f,
            details: {},
        }
    }

    getCharts=()=>{
        http.post('orders/get/details-service/'+this.props.id,{from:this.state.from,to:this.state.to})
            .then(response => {
                this.setState({
                    loadingForm: false,
                    details:response.data.details,
                });
            });
    }


    componentDidMount() {
        Emitter.on('reloadData', () => this.getCharts());
    }

    componentWillUnmount() {
        Emitter.off('reloadData');
    }

    render() {

        return (
            this.state.loadingForm ?
                <SkeleteLoading>
                    <div className=" mt-4">
                        <fieldset className={'mb-3'}>
                            <legend>جزییات</legend>
                            <div className="row">
                                <div className="col-md-3">
                                    <Squre height={100}/>

                                </div>
                                <div className="col-md-3">
                                    <Squre height={100}/>

                                </div>
                                <div className="col-md-3">
                                    <Squre height={100}/>

                                </div>
                                <div className="col-md-3">
                                    <Squre height={100}/>

                                </div>
                            </div>
                        </fieldset>
                    </div>
                </SkeleteLoading>
                :
                <React.Fragment>
                    <fieldset className={'mb-3'}>
                        <legend>جزییات</legend>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-body dashboard-rr medium">
                                    <div className="d-flex flex-column justify-content-between align-items-center">
                                        <div className="card-title">میزان</div>
                                        <div className="balance">{spilitNumber(this.state.details.amount)}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div  className="card card-body dashboard-rr medium">
                                    <div className="d-flex flex-column justify-content-between align-items-center">
                                        <div className="card-title">میانگین نرخ</div>
                                        <div className="balance">{spilitNumber(Math.round(this.state.details.currency))}<small className="ml-1 mr-1">تومان</small></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div  className="card card-body dashboard-rr medium">
                                    <div className="d-flex flex-column justify-content-between align-items-center">
                                        <div className="card-title">مجموع</div>
                                        <div className="balance">{spilitNumber(this.state.details.total)}<small className="ml-1 mr-1">تومان</small></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </React.Fragment>


        )
    }

}

export default CashesServiceDetails;
