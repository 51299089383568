import React, {Component} from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import loading from "../../assets/images/loading2.svg";
import error_messages from "../../ubold/commons/errors";
import TextArea from "./TextArea";
import CheckBox from "./CheckBox";
import Plans from "./plans";
import InputMask from 'react-input-mask';

import InputPassword from "./inputPassword";

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errors: {},
            loading: false,
            maxNumberLength:10
        };

        this.errorMessages = error_messages;
    }

    toggle() {
        this.setState({
            loading: !this.state.loading,
        });
    }

    validate = () => {

        const options = {abortEarly: false, allowUnknown: true};
        const {error} = Joi.validate(this.state.data, this.schema, options);

        if (!error) return null;

        const errors = {};
        for (let item of error.details) {
            const cerror = error ? this.buildUsefulErrorObject(item) : null;
            errors[item.path[0]] = (cerror ? cerror : item.message);
        }

        return errors;
    };

    validateProperty = (input) => {
        let value = input.value;
        let name = input.name;

        if (input.type === 'checkbox' || input.type === 'radio')
            value = input.checked;

        if (input.offsetParent !== null) {
            const obj = {[name]: value};
            const schema = {[name]: this.schema[name]};
            const {error} = Joi.validate(obj, schema);
            const cerror = error ? this.buildUsefulErrorObject(error.details[0]) : null;
            const err = error ? (cerror ? cerror : error.details[0].message) : null;
            return err;
        }
        return null;
    };

    buildUsefulErrorObject = (error) => {
        var arr = error.type.split('.');
        var msg = null;
        var rs = null;

        arr.map((t) => {
            if (!rs) {
                if (this.errorMessages[t])
                    rs = this.errorMessages[t];
                else
                    rs = null;
            } else {
                if (rs[t])
                    rs = rs[t];
                else
                    rs = null;
            }

            msg = rs;

            return msg;

        });

        if (msg)
            msg = error.context.label + ' ' + msg;

        return msg;
    }

    checkErrors = () =>{
        const errors = this.validate();
        this.setState({errors: errors || {}});
    }

    changeNumner=({currentTarget: input})=>{
        if(input.value.length > this.state.maxNumberLength)
            return false;
    }

    handleSubmit = e => {
        e.preventDefault();
        const errors = this.validate();

        this.setState({errors: errors || {}});

        if (errors) return;
        this.toggle();

        this.doSubmit();
    };

    handleChange = ({currentTarget: input}) => {
        const errors = this.state.errors;
        let data = this.state.data;

        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        if (input.type === 'checkbox'){
            data[input.name] = input.checked;
        }
        if (input.type === 'file') {
            data[input.name] = input.files[0];
        } else
        {

            if(input.maxLength!==-1 && input.value.length <= input.maxLength)
                data[input.name] = input.value;
            else if(input.maxLength===-1 || input.maxLength===undefined) {
                    data[input.name] = input.value;
            }

        }


        if (input.type === 'checkbox'){
            if(!input.checked)
                delete data[input.name];
        }

        this.setState({data, errors},()=> this.otherProcess(input));
    };

    onChangeSchema = (name,label,required=false) => {
        const sch=this.schema;
        const errors=this.state.errors;
        if(required)
            sch[name]=Joi.string().required().label(label);
        else{
            sch[name]=Joi.label(label);
            delete errors[name];
            this.setState({errors});
        }

        this.schema=sch;
    }

    otherProcess = (input) => {

    }

    renderButton(label, classname = '', horizontalForm = false,icon='',disabled) {
        if (horizontalForm)
            return (
                <div key={10005} className='form-group row mt-4'>
                    <div className="col-md-3"></div>

                    <div className="col-md-9">
                        <button disabled={this.state.loading|| disabled}
                                className={classname ? 'btn btn-t ' + classname : 'btn btn-t'}>
                            <img alt={'loading'} style={{'display': this.state.loading ? 'inline-block' : 'none'}} height="20"
                                 className="ml-1"
                                 src={loading}/>
                            {icon?<span dangerouslySetInnerHTML={{__html: icon}}></span>:''}

                            {label}

                        </button>
                    </div>

                </div>
            );
        else
            return (
                <div key={10005} className='form-group'>
                    <button disabled={this.state.loading || disabled}
                            className={classname ? 'btn btn-t ' + classname : 'btn btn-t'}>
                        <img alt={'loading'} style={{'display': this.state.loading ? 'inline-block' : 'none'}} height="20"
                             className="ml-1"
                             src={loading}/>
                        {icon?<i dangerouslySetInnerHTML={{__html: icon}}></i>:''}

                        {label}

                    </button>
                </div>
            );
    }

    renderSelect(name, label, options, defaultValue = null, horizontalForm = false,params={}) {
        const {data, errors} = this.state;
        return (
            <Select
                key={name}
                name={name}
                value={data[name]}
                label={label}
                options={options}
                selected={defaultValue}
                defaultValue={defaultValue}
                onChange={this.handleChange}
                error={errors[name]}
                hor={horizontalForm}
                disabled={(params.disabled?'disabled':false)}
                readOnly={(params.readonly?'readonly':false)}
            />
        );
    }

    renderPlans(plans,value) {
        const {data, errors} = this.state;

        return (
            <Plans
                value={data['fk_plan_id'] ? data['fk_plan_id'] : value}
                plans={plans}
                onChange={this.handleChange}
                error={errors['fk_plan_id']}
            />
        );
    }

    renderInput(name, label, type = "text", classn = '', value = null, checked = null, horizontalForm = false, defaultValue = '',placeHolder='',handleKeyUp,params={}) {
        const {data, errors} = this.state;
        return (
            type === 'file' ?
                <Input
                    key={label}
                    type={type}
                    name={name}
                    id={name}
                    label={label}
                    onChange={this.handleChange}
                    error={errors[name]}
                    classn={classn + ' dropify'}
                    hor={horizontalForm}
                    disabled={(params.disabled?'disabled':false)}
                    multiple={(params.multiple?'multiple':false)}
                    desc={(params.desc?params.desc:false)}
                />
                :
                type === 'radio' && type === 'checkbox' ?
                    (
                        type==='radio'?
                            <Input
                                type={type}
                                name={name}
                                value={data[name] ? data[name] : (value ? value : defaultValue)}
                                checked={value === checked}
                                label={label}
                                onChange={this.handleChange}
                                error={errors[name]}
                                classn={classn}
                                hor={horizontalForm}
                                desc={(params.desc?params.desc:false)}

                            />:
                            <Input
                                type={type}
                                name={name}
                                id={name}
                                value={value ? value : (defaultValue ? defaultValue : data[name])}
                                checked={checked}
                                label={label}
                                onChange={this.handleChange}
                                error={errors[name]}
                                classn={classn}
                                hor={horizontalForm}
                                desc={(params.desc?params.desc:false)}

                            />
                    )
                    :

                        <Input
                            maxlength={params.maxLength?params.maxLength:null}
                            type={type}
                            name={name}
                            id={name}
                            value={value ? value : data[name]}
                            label={label}
                            onChange={this.handleChange}
                            error={errors[name]}
                            classn={classn}
                            hor={horizontalForm}
                            handleKeyUp={handleKeyUp}
                            placeholder={placeHolder}
                            autoComplete={'off'}
                            symbol={(params.symbol?params.symbol:false)}
                            disabled={(params.disabled?'disabled':false)}
                            desc={(params.desc?params.desc:false)}

                        />



        );
    }

    renderPassword(name, label,  classn = '', horizontalForm = false, placeHolder='',handleKeyUp,params={}) {
        const {data, errors} = this.state;
        return (
            <InputPassword
                key={label}
                maxLength={params.maxLength?params.maxLength:null}
                autofocus={params.autofocus?params.autofocus:null}
                type={'password'}
                name={name}
                id={name}
                value={data[name]}
                label={label}
                onChange={this.handleChange}
                error={errors[name]}
                classn={classn}
                hor={horizontalForm}
                handleKeyUp={handleKeyUp}
                placeholder={placeHolder}
                autoComplete={'off'}
                symbol={(params.symbol?params.symbol:false)}
                disabled={(params.disabled?'disabled':false)}
                desc={(params.desc?params.desc:false)}

            />
        );
    }

    renderInputMask(name, label,mask='',className='',hor=false){
        const {data, errors} = this.state;
        const error=errors[name];

        return  <div                 key={label}
                                     className={hor?"form-group row horizontal align-item-center":"form-group"}>

            {label?<label className={hor?'col-md-3':''} htmlFor={name}>{label}</label>:''}

            {
                hor?
                    <div className='col-md-9'>
                        <InputMask id={name} onChange={this.handleChange} name={name} value={data[name]} className={'form-control ltr '+className} {...this.props} mask={mask} maskPlaceholder="111 " />
                        {error && <small className="font-11 error-label">{error}</small>}

                    </div>
                    :
                    <React.Fragment>
                        <InputMask id={name} onChange={this.handleChange} name={name} value={data[name]} className={'form-control ltr '+className} {...this.props} mask={mask} maskPlaceholder=" 111" />
                        {error && <small className="font-11 error-label">{error}</small>}
                    </React.Fragment>

            }

        </div>
    }

    renderCheckbox(name, checked = false) {
        return (
            <CheckBox
                key={name}
                checked={checked}
                name={name}
                onChange={this.handleChange}
            />
        );
    }

    renderTextArea(name, label, classn = '', horizontalForm = false, rows = 3,value='') {
        const {errors} = this.state;
        return (
            <TextArea
                key={label}
                name={name}
                label={label}
                onChange={this.handleChange}
                error={errors[name]}
                classn={classn}
                rows={rows}
                value={value}
                hor={horizontalForm}
            />
        )
    }

    convertToFormData(){
        const data=this.state.data;
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        return formData;
    }

}

export default Form;


