import React from 'react';
import Squre from "../skelete/Squre";
import SkeleteLoading from "../SkeleteLoading";
import {spilitNumber} from "../../services/helpers";

class FinanceLoading extends React.Component {

    render(){
        return (
            <React.Fragment>
                <SkeleteLoading>
                    <fieldset>
                        <legend>ورودی و خروجی ها</legend>
                        <div className="row">
                            <div className="col-md-3"><Squre height={100}/></div>
                            <div className="col-md-3"><Squre height={100}/></div>
                            <div className="col-md-3"><Squre height={100}/></div>
                            <div className="col-md-3"><Squre height={100}/></div>
                        </div>
                    </fieldset>

                </SkeleteLoading>
            </React.Fragment>
        );
    }
}

export default FinanceLoading;
