import React from "react";

import {spilitNumber} from "../../services/helpers";

const Plans = ({plans, value,error,onChange}) => {
  return (
          <React.Fragment>
              <div className="form-group ">
                  <label className="form-label ">
                      یکی از پلن های زیر را انتخاب کنید
                      <span className="label-require">*</span>
                  </label>
                  <div >
                      <div id="new-order-list">
                          <div className="services_root_list plan_root_list">

                              {
                                plans.map(plan=>
                                    <div key={plan.id} className=" ">
                                        <div className="radio-container">
                                            <input onChange={onChange} checked={value===plan.id} name="fk_plan_id" type="radio"  value={plan.id} id={plan.id} className="radio_cat"/>
                                            <div className={value===plan.id?'card active':'card '}>
                                                <div className="body">
                                                    <label className="radio_box float-right"><span
                                                        className="circle"></span>{plan.fa_name}
                                                        <small>{plan.en_name}</small></label>
                                                    <div className="float-left">
                                                        <div className="price "
                                                             style={{direction: 'ltr',textAlign: 'left'}}>{spilitNumber(plan.amount)}
                                                        </div>
                                                    </div>
                                                    <div className="clearfix "></div>
                                                    <div className="mt-2">
                                                        {plan.details?
                                                            plan.details
                                                            :''}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {error && <small className="font-11 error-label">{error}</small>}
                                    </div>
                                )
                              }

                          </div>

                      </div>
                  </div>

              </div>

          </React.Fragment>
  );
};

export default Plans;
