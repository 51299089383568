import React from 'react';
import {ToastContainer} from "react-toastify";
import $ from "jquery";

function initCols() {
    $(".right").css('min-height', $(window).height() + 'px');
}


class AuthLayout extends React.Component {

    componentDidMount() {
        initCols();
        $(window).resize(function () {
            initCols();
        });
        $("body").addClass('auth-body')

        $("input").focus(function (){
            $(this).closest('.form-group').addClass('focus');
        });

        $("input").focusout(function (){
            if($(this).val()==='')
                $(this).closest('.form-group').removeClass('focus');
        });

    }

    render() {
        return (
            <main className="auth-main  ">

                <ToastContainer position="top-right"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={true}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover/>
                <div className="container">

                <div className="row d-flex align-content-center align-items-center justify-content-center vhauth-100">


                    {this.props.children}
                </div>
            </div>
            </main>
        );
    }
}

export default AuthLayout;
